import {
  getList,
  exportOrder, //导出
} from "./api";
import cancelModal from "./../../channelReturns/distributorReturnList/components/cancelModal";
import Util from "@/utils/utils";

export default {
  components: {
    cancelModal,
  },
  name: "haixinReturnList",
  data() {
    return {
      visibleTicket: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "",
          name: "",
          title: "海信退货明细",
        },
      ],
      navList: [],
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      goodsList: [],
      invalidList: [], //批量失效
      insearchOBJ: [],
      creatDate: 1,
      paramList: {
        sortOrder: 'desc',
        sortName: 'created_date'
      },
      searchzz: false,
      loadings: false,
      // 暂无数据
      NoDate: false,
      nolistImg: require("@/assets/order/noList.png"),
      // 暂无更能多数据
      noMore: false,
      // 查看更多
      loadMores: true,
      goodsNmuber: 0,
      pageLoadFlag: true,
      requestdata: {},
      isLoading: false,
      isSub: 0, // 默认主账户
      onlyZhu: true,
      onlyChid: false,
      openType: "",
      visibleshop: false,
      visiblecancelApply: false,
      salesOrderId: "",
      saleItem: {},
      visibleCancel: false,
    };
  },
  beforeCreate() {
    // 添加背景色
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#F7F7F7");
  },
  beforeDestroy() {
    window.removeEventListener("storage", function(e) {});
    document.querySelector("body").setAttribute("style", "");
  },

  mounted() {
    
  },
  methods: {
    seeTicket(item){
      console.log(item)
      this.$refs.ticket.show(item.orderCode)
    },
    //表单筛选
    inputSearch(arg) {
      console.log('inputSearch')
      this.paramList = {
        ...this.paramList,
        ...arg[0],
      };
      this.page.pageNo = 1;
      this.searchzz = true;
      this.pageLoadFlag = true;
      this.getCommodityList();
    },
    // 时间排序
    changeFang() {
      if (this.creatDate == 0) {
        this.creatDate = 1;
        this.paramList.sortOrder = "desc";
      } else if (this.creatDate == 1) {
        this.creatDate = 2;
        this.paramList.sortOrder = "asc";
      } else {
        this.creatDate = 0;
        this.paramList.sortOrder = "";
      }
      this.getCommodityList();
    },

    // 导出
    exportClick() {
      let data = {
        ...this.requestdata,
        formCode: "returnOrderList",
      };
      this.isLoading = true;
      exportOrder(data).then((res) => {
        console.log(112, res)
        this.isLoading = false;
        Util.blobToJson(res.data)
          .then((content) => {
            console.log(113, content)
            if (content && content.msg == "success") {
              this.$message.success("下载成功，请到下载中心查看!");
            }
          })
          .catch((err) => {
            console.log(114, err)
            Util.downloadFile(res.data, "海信退货列表.xls");
          })
          .finally(() => {
            this.expLoading = false;
          });
      });
    },
    downloadFile(params) {
      var blob = params;
      if (
        "download" in document.createElement("a") &&
        navigator.userAgent.indexOf("Edge") == -1
      ) {
        var elink = document.createElement("a");
        elink.download = "残次退列表.xls";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, "残次退列表.xls");
      }
    },
    // 商品选择
    chooseItem(data, index, e) {
      this.goodsList[index].checked = e.target.checked;
      var pushY = this.invalidList.indexOf(data);
      if (pushY == -1) {
        this.invalidList.push(data);
      } else {
        this.invalidList.splice(pushY, 1);
      }
      this.$forceUpdate();
    },

    //查看订单详情
    toDetail(id, source) {
      let routeUrl = this.$router.resolve({
        path: "/hisenseReturn/hisenseReturn",
        query: {
          id: id,
          source
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    //  发起退货

    gotoReturn() {
      let routeUrl = this.$router.resolve({
        path: "/hisenseReturn/hisenseRrturnInputNew",
      });
      window.open(routeUrl.href, "_blank");
    },

    // 查看历史退残单
    gotoOldPage() {
      let routeUrl = this.$router.resolve({
        path: "/hisenseReturn/defectiveReturnList",
      });
      window.open(routeUrl.href, "_blank");
    },

    //鉴定结果uo
    orderReturn(id) {
      let routeUrl = this.$router.resolve({
        path: "/hisenseReturn/ReturnApplicationDetails",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 查看订单进度
    toProgress(type, id) {
      // if (type == "retail") {
      // this.$router.push({
      //     path: '/retailOrder/progress',
      //     query: {
      //         id
      //     }
      // })
      let routeUrl = this.$router.resolve({
        path: "/hisenseReturn/hisenseReturnDetail",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },

    // 商品列表
    getCommodityList() {
      console.log('getCommodityList')
      let data = {
        // formCode: 'defectiveList',
        pageNo: this.page.pageNo,
        ...this.paramList,
      };
      this.requestdata = data;
      this.pageLoadFlag = true;
      console.log('data11', data)
      getList(data).then((res) => {
        console.log("res1", res);
        this.pageLoadFlag = false;
        var realDate = res.data;
        this.goodsNmuber = realDate.totalRows;
        this.loadings = false;
        if (this.searchzz) {
          this.goodsList = realDate.list;
        }
        if (realDate.list.length == 0 && this.page.pageNo != 1) {
          this.noMore = true;
          this.loadMores = false;
          //没有更多数据
        } else if (realDate.list.length != 0 && this.page.pageNo != 1) {
          this.goodsList = this.goodsList.concat(realDate.list);
          this.$forceUpdate();
        } else if (realDate.list.length != 0 && this.page.pageNo == 1) {
          this.goodsList = realDate.list;

          this.NoDate = false;
          this.noMore = false;
          this.loadMores = true;
        } else if (realDate.list.length == 0 && this.page.pageNo == 1) {
          this.NoDate = true;
          this.noMore = false;
          this.loadMores = false;
        }

        if (
          realDate.list &&
          realDate.list.length != 0 &&
          this.page.pageNo * this.page.pageSize >= realDate.totalRows
        ) {
          this.noMore = true;
          this.loadMores = false;
        }
        for (let i = 0; i < this.goodsList.length; i++) {
          this.goodsList[i].checked = false;
        }
      });
    },
    // 加载更多
    loadMore() {
      this.loadings = true;
      this.page.pageNo++;
      this.pageLoadFlag = true;
      let data = {
        pageNo: this.page.pageNo,
        ...this.paramList,
        isNew: 1,
      };
      getList(data).then((res) => {
        this.pageLoadFlag = false;
        var realDate = res.data;
        this.goodsNmuber = realDate.totalRows;
        this.loadings = false;
        this.goodsList = this.goodsList.concat(realDate.list);
        if (realDate.currentPage > realDate.totalPages) {
          this.noMore = true;
          this.loadMores = false;
        }
      });
    },
  },
};
